<template>
  <b-form-group
  >
    <v-select
        v-model="localValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :clearable="false"
        @input="selected"
        label="name"
        :reduce="val => val.id"
        placeholder="Select Location"
        :options="options"
    />
  </b-form-group>
</template>

<script>
import vSelect from "vue-select";

export default {
  name: "CustomSelect",
  components: {
    vSelect
  },
  props: ['value', 'options'],
  data() {
    return {
      localValue: null
    }
  },
  computed: {
    isValidType() {
      if (!this.$store.state.app.TableOfQuantityModalSubmitted) {
        return true
      }
      return this.value;
    },
  },
  methods: {
    selected(value) {
      this.$emit('update:value', value)
      this.$emit('selected', value)
    }
  },
  mounted() {
    this.localValue = this.value
  }
}
</script>

<style scoped>

</style>
