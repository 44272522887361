<template>
  <b-modal @hidden="resetOffer" id="preview-offer-modal" size="lg" centered title="Offer Details" ok-only
           ok-title="Update">
    <template #modal-header>
      <div></div>
    </template>
    <b-overlay
        variant="white"
        :show="loading"
        spinner-variant="primary"
        blur="1"
        opacity=".75"
        rounded="sm"
    >
      <PDFViewer
          v-if="pdfLink"
          :source="pdfLink"
          @loaded="loading = false"
          @loading-failed="errorLoadingPDf"
      />
      <div v-if="errorPDF">
        <h4 class="text-center">Error loading PDF 😢</h4>
      </div>
      <!--            <data-preview :offer="offer" />-->
    </b-overlay>
    <template #modal-footer>
      <div class="w-100">
        <div class="d-flex float-right align-center align-items-center">
          <label for="" class="mr-1">With Stamp</label>
          <b-form-checkbox
              :checked="'No'"
              class="custom-control-primary mr-1"
              name="check-button"
              :disabled="loading"
              v-model="withStamp"
              switch
              @change="refreshOffer(offer)"
          />
          <b-button
              id="show-btn"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="float-right mr-1"
              variant="primary"
              :disabled="downloading || loading"
              @click="downloadPdf">
            <span v-if="!downloading">Download</span>
            <span class="mr-1" v-if="downloading">Downloading...</span>
            <b-spinner v-if="downloading" small/>
          </b-button>
          <b-button id="update-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :disabled="loading"
                    class="float-right mr-1"
                    variant="outline-primary" @click="goToUpdate(offer)">
            Update
          </b-button>
        </div>
      </div>
    </template>
    <offer-history ref="OfferHistory"/>
  </b-modal>
</template>

<script>
import OfferService from "@/services/OfferService";
import DataPreview from './DataPreview.vue'
import Ripple from 'vue-ripple-directive'
import {BButton, BSpinner} from 'bootstrap-vue'
import OfferHistory from './OfferHistory.vue'
import routeMixin from './RouteMixin.js';
import PDFViewer from "@/views/components/PDF-viewer/PDF-viewer";

export default {
  name: 'OfferList',
  components: {PDFViewer, DataPreview, BButton, BSpinner, OfferHistory},
  directives: {Ripple},
  mixins: [routeMixin],
  data() {
    return {
      offerService: new OfferService(),
      loading: undefined,
      offer: undefined,
      offerId: undefined,
      pdfLink: undefined,
      pdfName: undefined,
      errorPDF: false,
      downloading: false,
      withStamp: true,
    }
  },
  methods: {
    refreshOffer(offer) {
      console.log("offer is")
      console.log(offer)
      // this.$nextTick(() => {
      //   this.$bvModal.hide('preview-offer-modal')
      this.getOfferPdf(offer)
      // })
    },
    getOfferPdf(offer) {
      this.offerId = offer.id
      this.offer = offer
      this.loading = true;
      this.pdfLink = undefined
      this.$bvModal.show('preview-offer-modal');
      this.offerService.previewPDF(offer.id, this.withStamp).then(res => {
        this.pdfLink = res.url;
        this.pdfName = res.file_name;
      }).catch(err => {
      }).finally(() => {
        this.loading = false;
      });
    },
    downloadPdf() {
      this.downloading = true
      this.offerService.generateQuotationPdf(this.offerId, this.pdfName, this.withStamp).then(res => {
        this.downloading = false
        this.$nextTick(() => {
          this.$bvModal.hide('preview-offer-modal')
        })
      })

    },
    errorLoadingPDf() {
      this.loading = false
      this.errorPDF = true
    },
    resetOffer() {
      this.offerId = undefined;
      this.offer = undefined;
      this.pdfLink = undefined;
      this.pdfName = undefined;
      this.errorPDF = false;
      this.withStamp = true
    }
  },
}
</script>
