<template>
  <div class="card">
    <div class="card-body">
      <h4>Accessories</h4>
      <div class="row">
        <div class="col-md-6">
          <Checkbox
              ref="Checkbox"
              class="mb-0"
              @checkboxChanged="updateAccessories($event,'accessories_model_ids')"
              @textChanged="updateAccessories($event,'accessories_model')"
              label="Model"
              :options="accessoriesModel"
              :selectedItems="selectedAccessoriesModel"
              :withOthers="true"
          />
        </div>
        <div class="col-md-6">
<!--          <Checkbox-->
<!--              ref="Checkbox"-->
<!--              class="mb-0"-->
<!--              @checkboxChanged="updateAccessories($event,'accessories_location_ids')"-->
<!--              label="Fixing Location"-->
<!--              :options="accessoriesLocation"-->
<!--              :selectedItems="selectedAccessoriesLocation"-->
<!--          />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfferService from "@/services/OfferService";
import Checkbox from "@/views/components/seller/table of quantity/Checkbox";

export default {
  name: "Accessories",
  components: {Checkbox},
  props: [
    'offerId',
    // 'accessoriesLocation',
    // 'selectedAccessoriesLocation',
    'accessoriesModel',
    'selectedAccessoriesModel'
  ],
  data() {
    return {
      offerService: new OfferService()
    }
  },
  methods: {
    updateAccessories(value, key) {
      this.offerService.updateTableOfQuantity(this.offerId, {[key]: value}).then(res => {
      }).finally(() => {
      })
    },
  }
}
</script>

<style scoped>

</style>
