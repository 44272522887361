<template>
  <div class="card">
    <div class="card-body">
      <b-form-group :label="label">
        <div
            v-for="(option, index) in localOptions"
            class="d-flex align-items-center justify-content-between mb-1"
            :key="index"
        >
          <div>
            <b-form-checkbox
                v-model="option.checked"
                :disabled="disabled"
                name="check-button"
                :state="state"
                @input="checkboxChanged($event)"
            >
              {{ option.name }}
            </b-form-checkbox>
            <b-form-invalid-feedback :state="state">
              Please select one at least
            </b-form-invalid-feedback>
          </div>
          <b-form-input
              class="w-25"
              size="sm"
              placeholder="Note"
              v-model="option.note"
              :disabled="disabled"
              debounce="1000"
              @update="noteChanged($event)"
          />
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxWithNotes",
  props: ['label', 'options', 'selectedItems', 'disabled'],
  data() {
    return {
      localOptions: [],
      isEmpty: true
    }
  },
  computed: {
    state() {
      console.log(123)
      if (!this.$store.state.app.TableOfQuantityModalSubmitted) {
        return null
      }
      if (this.disabled) {
        return null
      }
      if (this.isEmpty) {
        return false
      } else {
        return null
      }
    },
  },
  methods: {
    checkboxChanged(checked) {
      this.isEmpty = true
      const emittedData = []
      this.localOptions.forEach(option => {
        if (option.checked) {
          this.isEmpty = false
          emittedData.push({id: option.id, note: option.note ?? undefined})
        }
      })
      this.$emit('Changed', emittedData)
    },
    noteChanged(event) {
      const emittedData = []
      this.localOptions.forEach(option => {
        console.log(option.checked)
        if (option.checked) {
          this.isEmpty = false
          emittedData.push({id: option.id, note: option.note ?? undefined})
        }
      })
      this.$emit('Changed', emittedData)
    }
  },
  mounted() {
    this.localOptions = JSON.parse(JSON.stringify(this.options))
    this.localOptions.forEach(opt => {
      const matchedItem = this.selectedItems.find(item => item.id === opt.id)
      if (matchedItem) {
        opt.checked = true
        this.isEmpty = false
        opt.note = matchedItem.note
      }
    })
  }
}
</script>

<style scoped>

</style>
