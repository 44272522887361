<template>
  <div class="card">
    <div class="card-body">
      <div class="">
        <h4 class="d-inline-flex w-60">Items List</h4>
        <h4 class="d-inline-flex justify-content-center w-40">Fixing Location</h4>
      </div>
      <div class="d-flex">
        <div class="d-flex flex-column w-60" style="gap: 25px">
          <div v-for="(title, index) in leftTitles"
               :key="index"
               class="d-flex" style="gap: 25px">

            <div class="title-width d-flex title-bg">
              <span class="m-auto align-self-center text-center">{{ title }}</span>
            </div>
            <div class="d-flex flex-wrap" style="gap: 25px">
            <span v-for="(name, index) in localItems[title].names"
                  :key="index"
                  class="category items-width text-center"
            >
              {{ name }}
            </span>
            </div>
          </div>
        </div>
        <div class="w-40 d-flex flex-column justify-content-between pl-1 border-left">
          <div v-for="(title, index) in leftTitles"
               :key="index"
          >
            <div class="row">
              <div class="col-6">
                <CustomSelect
                    :value.sync="localItems[title].accessories_location.id"
                    @selected="selectLocation($event,title, localItems[title].accessories_location.note)"
                    :options="accessoriesLocation"
                />
              </div>
              <div class="col-6">
                <b-form-group>
                  <b-form-input
                      placeholder="Note"
                      debounce="1000"
                      @update="selectLocation(localItems[title].accessories_location.id,title, $event)"
                      v-model="localItems[title].accessories_location.note"/>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from "./CustomSelect";
import OfferService from "@/services/OfferService";

export default {
  name: "ItemsList",
  components: {
    CustomSelect
  },
  props: [
    'items',
    'accessoriesLocation',
    'offerId',
  ],
  data() {
    return {
      localItems: [],
      leftTitles: [],
      offerService: new OfferService()
    }
  },
  methods: {
    selectLocation(id, title, note) {
      if (id) {
        this.offerService.updateTableOfQuantity(this.offerId, {
          accessories_location: {
            item_name: title,
            accessories_location_id: id,
            note: note ?? undefined,
          }
        })
      }
    }
  },

  mounted() {
    this.localItems = JSON.parse(JSON.stringify(this.items))
    Object.keys(this.localItems).forEach((key) => {
      this.leftTitles.push(key)
    })
  }
}
</script>

<style scoped>
.title-width, .items-width {
  width: 230px;
}

.title-bg {
  background-color: #E6E6E6;
  padding: 0.3rem;
  border-radius: 6px;
}

.category {
  background-color: #FAFAFA;
  align-self: center;
  padding: 0.3rem;
  border-radius: 6px;
}

.category.red-bg {
  background: #ffc9c9;
}

.w-60 {
  width: 60%
}

.w-40 {
  width: 40%;
}
</style>
