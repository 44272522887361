<template>
  <b-card title="Offers Table">
    <div class="custom-search d-flex justify-content-end justify-content-sm-start mb-2 mb-sm-0">
      <div class="d-flex align-items-center">
        <b-button
            v-if="showAll"
            id="show-btn"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="float-right"
            variant="primary"
            :to="{ name: 'create-offer' }"
        >Add Offer
        </b-button>
      </div>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <v-select
          id="publish_status"
          class="custom-select-style mr-2"
          :loading="isLoading"
          :disabled="isLoading"
          v-model="params.publish_status"
          :options="['Draft', 'Published']"
          placeholder="Publish status" label="Status"/>
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="params.search"
              placeholder="Search"
              type="text"
              class="d-inline-block"
              debounce="1000"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        ref="offerTable"
        mode="remote"
        :pagination-options="{
        enabled: true,
      }"
        :total-rows="totalRecords"
        :rows="rows"
        :columns="columns"
        :is-loading.sync="isLoading"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
                right
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                  v-if="showAll"
                  @click="showRemovePopup(props.row.id)"
              >
                <span
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                >
                  Delete
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="showAll"
                  @click="goToUpdate(props.row)"
              >
                <span
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                >
                  Update
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="showAll && props.row.publish_status==='Published'"
                  @click="showPreviewModal(props.row)"
              >
                <span
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                >
                  Preview
                </span>
              </b-dropdown-item>
              <!--              <b-dropdown-item v-if="showAll" @click="generateQuotation(props.row.id)">-->
              <!--                <span v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">-->
              <!--                  Generate Quotation-->
              <!--                </span>-->
              <!--              </b-dropdown-item>-->
              <b-dropdown-item
                  v-if="showAll && props.row.publish_status==='Published'"
                  @click="viewTableOfQuantity(props.row)"
              >
                <span
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                >
                  Table of Quantity
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="showAll && props.row.publish_status==='Published'"
                  @click="openOfferHistoryModel(props.row)"
              >
                <span
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                >
                  Show Log
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else-if="props.column.field === 'offer_progress'">
          <span
              v-if="props.row.publish_status==='Published'"
              class="d-flex justify-content-between"
              :class="{'skeleton-box': props.row.savingOfferStatus}"
          >
            <span>
              <span
                  :style="{color:getOfferProgressColor(props.row.offer_progress)}"
              >{{ props.row.offer_progress }}</span>
              <feather-icon
                  v-if="showHint(props.row)"
                  v-b-popover.hover.top="props.row.reject_reason"
                  class="ml-1 cursor-pointer"
                  title="Reason"
                  size="18"
                  icon="InfoIcon"
              />
            </span>
            <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                  v-for="status in offerProgressStatus "
                  @click="changeOfferStatus(props.row, status.value)"
              >
                <span
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                >
                  {{ status.value }}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span
            v-else-if="props.column.field === 'job_card' && props.row.publish_status==='Published'"
        >
          <span
              v-if="checkPermission()"
              :class="props.row.offer_progress === offerProgressStatus.REJECTED.value ? 'rejected-job-card text-muted' : ''"
          >
            {{ props.row.job_card }}
          </span>
          <InlineCellEdit
              v-else
              :loading="props.row.savingJobCard"
              :value="props.row.job_card"
              @changed="updateJobCard($event , props.row)"
          />
          <span class=""/>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template #emptystate>
        <p class="text-center">
          {{ isOPen ? "Loading..." : "This will show up when there are no rows" }}
        </p>
      </template>
    </vue-good-table>

    <preview-offer ref="previewOffer"/>
    <TableOfQuantityModal ref="TableOfQuantityModal"/>
    <DeleteModel
        ref="deleteModel"
        @result="remove"
    />
    <TextAreaInputModal
        ref="TextAreaInputModal"
        @result="onRejectReason"
    />
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BCard,
  BButton,
  VBPopover,
  VBModal,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import OfferService from '@/services/OfferService'
import Ripple from 'vue-ripple-directive'
import InlineCellEdit from '@/views/components/shared/InlineCellEdit'
import TextAreaInputModal from '@/views/components/shared/TextAreaInputModal'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import TableOfQuantityModal from '@/views/components/seller/table of quantity/TableOfQuantityModal'
import DeleteModel from './DeleteModel.vue'
import routeMixin from './RouteMixin.js'
import PreviewOffer from './PreviewOffer.vue'
import vSelect from 'vue-select'

export default {
  name: 'OfferList',
  components: {
    TableOfQuantityModal,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    PreviewOffer,
    BCard,
    BButton,
    DeleteModel,
    InlineCellEdit,
    TextAreaInputModal,
    vSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-popover': VBPopover,
  },
  mixins: [routeMixin],
  props: {
    showAll: {
      Type: Boolean,
      default: true,
    },
    propsParams: {
      Type: Object,
    },
  },
  data() {
    return {
      offerProgressStatus: {
        ACCEPTED: {
          value: 'Accepted',
          color: '#70AD47',
        },
        REJECTED: {
          value: 'Rejected',
          color: '#f00',
        },
        ONGOING: {
          value: 'On Going',
          color: '#F4C522',
        },
      },
      params: {
        page: undefined,
        per_page: undefined,
        sort_keys: undefined,
        sort_dir: undefined,
        search: undefined,
        publish_status: undefined,
      },
      columns: [
        {label: 'Offer Reference Number', field: 'serial_number'},
        {label: 'Project Name', field: 'project_name'},
        {label: 'Customer', field: this.customer},
        {label: 'Project Date', field: 'project_date'},
        {label: 'Offer Status', field: 'offer_progress'},
        {label: 'Job Card', field: 'job_card'},
        {label: 'Publish Status', field: 'publish_status'},
        {label: 'Creation Date', field: 'created_at'},
        {label: 'Action', field: 'action'},
      ],
      rows: [],
      isLoading: undefined,
      isOPen: true,
      totalRecords: undefined,
      offerService: new OfferService(),
      modalOptions: undefined,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    params: {
      handler() {
        this.getOffers()
      },
      deep: true,
    },
  },
  created() {
    this.getOffers()
  },
  methods: {
    getOfferProgressColor(status) {
      switch (status) {
        case this.offerProgressStatus.ACCEPTED.value:
          return this.offerProgressStatus.ACCEPTED.color
        case this.offerProgressStatus.REJECTED.value:
          return this.offerProgressStatus.REJECTED.color
        case this.offerProgressStatus.ONGOING.value:
          return this.offerProgressStatus.ONGOING.color
      }
    },
    updateJobCard(value, item) {
      const pattern = /^\d{2}-\d{4}$/
      if (!pattern.test(value)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Action denied',
            icon: 'AlertTriangleIcon',
            text: 'Job card should be like ##-####',
          },
        })
        return
      }
      if (value == '' && item.offer_progress === this.offerProgressStatus.ACCEPTED.value) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Action denied',
            icon: 'AlertTriangleIcon',
            // variant: status ? 'success' : 'danger',
            // eslint-disable-next-line camelcase
            text: 'Job card can\'t be empty',
          },
        })
        return
      }
      const oldValue = item.job_card
      item.job_card = value
      item.savingJobCard = true
      this.offerService.updateJobCard(item.id, value).then(res => {
        item.savingJobCard = false
      }, err => {
        item.savingJobCard = false
        console.error(err)
        item.job_card = oldValue
      })
    },
    showHint(offer) {
      return offer.offer_progress === this.offerProgressStatus.REJECTED.value && offer.reject_reason
    },
    customer(rowObj) {
      return `${rowObj.customer.first_name} ${rowObj.customer.last_name}`
    },
    onPerPageChange(page) {
      console.log(page)
      this.$refs.offerTable.reset()
      this.params.page = 1
      this.params.per_page = page.currentPerPage
    },
    onPageChange(active) {
      this.params.page = active.currentPage
      this.params.per_page = active.currentPerPage
    },
    onSortChange(params) {
      const SortKey = typeof params[0].field === 'function' ? 'customer' : params[0].field
      this.$refs.offerTable.reset()
      this.params.page = 1
      this.params.sort_keys = [SortKey]
      this.params.sort_dir = [params[0].type]
    },
    getOffers() {
      this.isLoading = true
      this.offerService.index({...this.params, ...this.propsParams}).then(res => {
        res.data.forEach(row => {
          row.savingJobCard = false
          row.savingOfferStatus = false
        })
        this.rows = res.data
        this.totalRecords = res.total
      }).catch(err => {
      })
          .finally(() => {
            this.isLoading = false
            this.isOPen = false
          })
    },
    showRemovePopup(id) {
      this.$refs.deleteModel.open(id)
    },
    showPreviewModal(offer) {
      this.$refs.previewOffer.getOfferPdf(offer)
    },
    remove(id) {
      this.isLoading = true
      this.offerService.delete(id).then(res => {
        this.getOffers()
      }).catch(err => {
      })
          .finally(() => {
            this.isLoading = false
            this.isOPen = false
          })
    },
    openOfferHistoryModel(offer) {
      this.$refs.previewOffer.$refs.OfferHistory.openModel(offer)
    },
    // generateQuotation(id) {
    //   this.offerService.generateQuotationPdf(id)
    // },
    viewTableOfQuantity(offer) {
      if (offer.offer_progress !== this.offerProgressStatus.ACCEPTED.value) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Action denied',
            icon: 'AlertTriangleIcon',
            text: 'Offer status should be accepted first',
          },
        })
        return
      }
      const modalComponent = this.$refs.TableOfQuantityModal
      modalComponent.$data.offerId = offer.id
      modalComponent.open()
    },
    changeOfferStatus(offer, status) {
      if (offer.offer_progress !== status) {
        switch (status) {
          case this.offerProgressStatus.REJECTED.value:
            this.$refs.TextAreaInputModal.open(offer)
            break
          case this.offerProgressStatus.ACCEPTED.value:
            offer.savingOfferStatus = true
            this.offerService.updateOfferStatus(offer.id, status).then(res => {
              const index = this.rows.findIndex(row => row.id === offer.id)
              this.rows[index].offer_progress = status
              this.rows[index].job_card = res.job_card
            }, err => {
            }).finally(() => {
              offer.savingOfferStatus = false
            })
            // } else {
            //   this.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: 'Action denied',
            //       icon: 'AlertTriangleIcon',
            //       // variant: status ? 'success' : 'danger',
            //       // eslint-disable-next-line camelcase
            //       text: 'Please fill the Job Card filed first',
            //     },
            //   })
            // }
            break
          case this.offerProgressStatus.ONGOING.value:
            offer.savingOfferStatus = true
            this.offerService.updateOfferStatus(offer.id, status).then(res => {
              const index = this.rows.findIndex(row => row.id === offer.id)
              this.rows[index].offer_progress = status
              this.rows[index].job_card = res.job_card
              offer.offer_progress = status
            }).finally(() => {
              offer.savingOfferStatus = false
            })
            break
        }
      }
    },
    onRejectReason(event) {
      this.$refs.TextAreaInputModal.loading = true
      this.offerService.updateOfferStatus(event.data.id, this.offerProgressStatus.REJECTED.value, event.text).then(res => {
        const index = this.rows.findIndex(row => row.id === event.data.id)
        this.rows[index].offer_progress = this.offerProgressStatus.REJECTED.value
        this.rows[index].reject_reason = event.text
        this.$nextTick(() => {
          this.$bvModal.hide('TextAreaInputModal')
        })
      }).finally(() => {
        this.$refs.TextAreaInputModal.loading = false
      })
    },
    checkPermission() {
      return !(this.userData.roles[0].name === 'Super_Admin')
    },
  },
}
</script>
<style>
.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

.rejected-job-card {
  text-decoration: line-through;
}

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
}

@media (max-width: 576px) {

  th,
  td {
    /* font size */
    font-size: 0.857rem;
    font-weight: 400;
    /* text center horizontally */
    vertical-align: middle !important;
    text-align: center;
    line-height: 20px;
  }
}
</style>

<style scoped>
@media (min-width: 576px) {
  ::v-deep .vgt-responsive {
    overflow: inherit;
  }
}

.custom-select-style {
  min-width: 250px;
  margin-right: 6px;
}
</style>
