<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h4>Aluminum Color</h4>
        <transition-group name="slide-fade">
          <div v-for="(color, index) in localSelectedColor"
               :key="color.index"
               class="position-relative pt-1">
            <ColorSelect
                :colors="colors"
                :index="index"
                :withPlusIcon="true"
                :colorsLength="localSelectedColor.length"
                :selectedColor="color"
                @removeColor="removeColor"
                @addNewColor="addNewColor"
                @selected="colorCodeChanged($event , index)"
                @noteChange="colorNoteChanged($event , index)"
            />
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormGroup, BImgLazy} from "bootstrap-vue";
import vSelect from "vue-select";
import ColorSelect from "@/views/components/seller/table of quantity/aluminum color section/ColorSelect";

export default {
  name: "AluminumColorSection",
  props: ['colors', 'selectedColor'],
  components: {
    ColorSelect,
    BFormGroup,
    BImgLazy,
    vSelect
  },
  data() {
    return {
      payloadSelectedColor: [],
      localSelectedColor: []
    }
  },
  computed: {},
  mounted() {
    this.localSelectedColor = this.selectedColor
    if (!this.localSelectedColor.length) {
      this.localSelectedColor.push({media: [], type: null, id: null, index: 0})
    }
    this.localSelectedColor.forEach((color, index) => {
      color.index = index
      this.payloadSelectedColor.push({id: color.id, note: color.note ?? undefined})
    })
  },
  methods: {
    addNewColor() {
      this.payloadSelectedColor.push({id: undefined, note: undefined})
      this.localSelectedColor.push({
        media: [],
        type: null,
        id: null,
        index: this.localSelectedColor[this.localSelectedColor.length - 1].index + 1
      })
    },
    removeColor(index) {
      this.localSelectedColor.splice(index, 1)
      this.payloadSelectedColor.splice(index, 1)
      this.emitData()
    },
    colorCodeChanged(colorId, index) {
      this.payloadSelectedColor[index].id = colorId
      this.emitData()
    },
    colorNoteChanged(colorNote, index) {
      this.payloadSelectedColor[index].note = colorNote
      this.emitData()
    },
    emitData() {
      // const data = .map(obj => Object.keys(obj).length === 0 ? undefined : obj)
      const removedIndex = []
      const emitData = JSON.parse(JSON.stringify(this.payloadSelectedColor))
      this.payloadSelectedColor.forEach((obj, index) => {
        if (!obj.id) {
          removedIndex.unshift(index)
        }
      })
      removedIndex.forEach(index => {
        emitData.splice(index, 1)
      })
      this.$emit('change', emitData)
    }
  }
}
</script>

<style scoped>
.color-sample {
  height: 38px;
  border-radius: 6px;
  width: 100%;
  object-fit: cover;
}
</style>
