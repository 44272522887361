const routeMixin = {
    methods: {
        goToUpdate(row) {
            this.$router.push({
                name: "update-offer",
                params: {
                    child_id: row.id,
                    parent_id: row.publish_status === 'Draft' ? row.publish_status : (row.parent_id == null ? row.id : row.parent_id),
                },
            });
        },
    },
};

export default routeMixin;
