<template>
    <b-modal @hidden="resetVersion" id="modal-versions" size="xl" centered ok-only ok-title="Close">
        <template #modal-header>
            <h5 class="modal-title">Offer Versions</h5>
            <v-select id="versions" class="d-block mr-auto ml-2 d-md-none custom-select-style" :loading="loading" v-model="versionStep" :reduce="option => option.serial_number" 
            :options="versions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Select Version" label="serial_number" />
        </template>
        <div v-if="loading" class="loader-overlay">
            <b-spinner style="width: 4rem; height: 4rem;" variant="primary" />
        </div>
        <b-tabs vertical end nav-class="nav-right" nav-wrapper-class="tabs-border nav-vertical d-none d-md-block">
            <template #tabs-start>
                <h3 class="font-weight-bold text-center mb-2" role="presentation">Versions</h3>
            </template>
            <b-tab v-for="(version, index) in versions" :key="version.id" :active="versionStep == version.serial_number"
                :title="version.serial_number">
                <data-preview :offer="version" />
            </b-tab>
        </b-tabs>
    </b-modal>
</template>

<script>
import { BTabs, BTab, BCardText, BSpinner } from 'bootstrap-vue'
import OfferService from "@/services/OfferService"
import DataPreview from './DataPreview.vue'
import vSelect from 'vue-select'

export default {
    components: { vSelect, BCardText, BTabs, BTab, BSpinner, DataPreview },
    data() {
        return {
            offerService: new OfferService(),
            versions: undefined,
            versionStep: undefined,
            loading: true
        }
    },
    methods: {
        openModel(offer) {
            const id = offer.parent_id == null ? offer.id : offer.parent_id;
            this.getParentOffer(id)
            this.$bvModal.show('modal-versions');
        },
        getParentOffer(id) {
            this.loading = true
            this.offerService.show(id).then(res => {
                let versions = res.versions;
                versions.unshift(res);
                this.versions = versions;
                this.versionStep = this.versions[0].serial_number;
            }).catch(err => { }).finally(() => {
                this.loading = false
            });
        },
        resetVersion() {
            this.versions = undefined;
        }
    },
}
</script>

<style scope>
.nav-tabs .nav-link.active {
    background: var(--primary);
    border-radius: 6px;
    color: #fff;
}

.tabs-border {
    border-left: 3px solid var(--primary);
}

@media (max-width: 576px) {
    .modal-title,
    .vs__selected {
        /* font size */
        font-size: 0.857rem;
        font-weight: 400;
        /* text center horizontally */
        vertical-align: middle !important;
        text-align: center;
    }
    .modal-title{
        line-height: 38px;
    }
}
</style>