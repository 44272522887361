<template>
    <b-modal id="delete-model" :centered="true">
        <b-container fluid>
            <template #modal-header />
            <b-row class="justify-content-center bg-white" style="background-color: #ffffff">
                <b-col cols="10">
                    <p style="color: #0E1A1A" class="font-weight-bold">{{ text }}</p>
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <BButton v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="text-capitalize mb-2 mr-2"
                    @click="submitModal(true)">
                    Ok
                </BButton>
                <BButton v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                    class="text-capitalize mb-2" @click="submitModal(false)">
                    Cancel
                </BButton>
            </b-row>

        </b-container>

        <template #modal-footer>
            <div></div>
        </template>
    </b-modal>
</template>
  
  <script>
import { BButton, BModal, BContainer, VBModal, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    name: "DeleteModel",
    components: {
        BButton,
        BContainer,
        BModal,
        BRow,
        BCol
    },
    directives: {
        'b-modal': VBModal,
        Ripple
    },
    data() {
        return {
            id: undefined,
        }
    },
    props: {
        text: {
            default: "Are you sure you want to do this action ?",
            type: String
        },
    },
    methods: {
        open(id) {
            this.id = id;
            this.$bvModal.show('delete-model');
        },
        submitModal(value) {
            if (value)
                this.$emit('result', this.id)
            this.$bvModal.hide('delete-model');
        },
    }
}
</script>
  
  <style scoped>
  </style>
  