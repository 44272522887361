<template>
  <div class="card">
    <div class="card-body">
      <b-form-group :label="label">
        <b-form-checkbox-group
            v-model="value"
            :options="localOptions"
            :state="state"
            class=""
            @input="checkboxChanged"
            name="checkbox-validation"
        >
          <b-form-invalid-feedback :state="state">
            Please select one at least
          </b-form-invalid-feedback>
        </b-form-checkbox-group>
      </b-form-group>
      <b-form-group v-if="withOthers" label="Others ">
        <b-form-input
            class="w-75"
            :debounce="1000"
            placeholder="Type here"
            @update="textChanged"
            v-model="text"
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
import {BFormCheckboxGroup, BFormGroup, BFormInvalidFeedback, BFormInput} from "bootstrap-vue";

export default {
  name: "Checkbox",
  props: ['options', 'selectedItems', 'withOthers', 'label'],
  components: {
    BFormCheckboxGroup,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput
  },
  data() {
    return {
      value: [],
      localOptions: [],
      text: '',
      isEmpty: true
    }
  },
  computed: {
    state() {
      if (!this.$store.state.app.TableOfQuantityModalSubmitted) {
        return null
      }
      if (this.text) {
        return null
      }
      if (this.value.length < 1) {
        return false
      } else {
        return null
      }
    },
  },
  methods: {
    checkboxChanged(event) {
      this.isEmpty = !event.length;
      if (this.isEmpty && this.withOthers) {
        this.isEmpty = !this.text
      }
      this.$emit('checkboxChanged', event)
    },
    textChanged(event) {
      if (!this.value.length) {
        this.isEmpty = !event
      }
      this.$emit('textChanged', event)
    },
    test(event) {
      console.log(event)
    }
  },
  mounted() {
    this.options.forEach(option => {
      this.localOptions.push({text: option.name, value: option.id})
    })
    if (this.selectedItems) {
      this.selectedItems.forEach(item => {
        if (typeof item === 'string') {
          this.text = item
        } else {
          this.value.push(item.id)
        }
      })
      if (this.value.length || this.text) {
        this.isEmpty = false

      }
    }
  }
}
</script>

<style scoped>

</style>
