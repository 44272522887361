<template>
  <div class="card">
    <div class="card-body d-flex" style="gap: 25px">
      <div class="title-width">
        <h4>Product Category</h4>
      </div>
      <div class="d-flex flex-wrap" style="gap: 25px">
        <span v-for="(category, index) in categories"
              :key="index"
              class="category title-width text-center"
        >
          {{ category }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCategory",
  props: ['categories']
}
</script>

<style scoped>
.title-width {
  width: 230px;
}

.category {
  background-color: #FAFAFA;
  align-self: center;
  padding: 0.3rem;
  border-radius: 6px;
}
</style>
