<template>
  <div class="card">
    <div class="card-body">
      <h4>Attachments</h4>
      <DropZone
          v-if="data.filesThumbnail"
          :classes="'col-6 col-sm-6 col-md-4 col-xl-2 mb-2'"
          :withNotes="true"
          :label="''"
          :query-param="'path=table_of_quantity'"
          :maxSize="30"
          :filesThumbnail="data.filesThumbnail"
          :filesIDs="data.files"
          @dataChanged="dataChanged"
      />
      <small v-if="!isValid" class="text-danger">Please add 4 files at least</small>
    </div>
  </div>
</template>

<script>
import DropZone from "@/views/components/file-uploader/DropZone";
import OfferService from "@/services/OfferService";

export default {
  name: "Attachments",
  props: ['offerId', 'attachments'],

  components: {DropZone},
  data() {
    return {
      data: {
        files: null,
        filesThumbnail: null,
      },
      offerService: new OfferService(),
    }
  },
  computed: {
    isValid() {
      if (!this.$store.state.app.TableOfQuantityModalSubmitted) {
        return true
      }
      return true
      // return this.data.files.length >= 4
    }
  },
  methods: {
    dataChanged(payload) {
      this.data.files = payload
      this.offerService.updateTableOfQuantity(this.offerId, {attachments: payload})
    },
  },
  mounted() {
    const files = []
    const filesThumbnail = []
    this.attachments.forEach(attach => {
      if (attach.media[0]) {
        files.push({file_id: attach.media[0].id, note: attach.note})
        filesThumbnail.push({...attach.media[0], note: attach.note})
      }
    })
    this.data.files = files
    this.data.filesThumbnail = filesThumbnail
  }
}
</script>

<style scoped>

</style>
