<template>
  <div class="card">
    <div class="card-body">
      <h4>Installation</h4>
      <div class="row">
        <div class="offset-md-3 col-md-6">
          <b-table
              :striped="true"
              :outlined="true"
              :items="installations"
              :fields="fields"
          >
            <template #cell(provider)="data">
              <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="providers"
                  code="id"
                  label="name"
                  size="sm"
                  style="width: 300px"
                  @input="saveProvider($event, data.item)"
                  v-model="selectedProviders[data.index]"
                  :clearable="false"
                  :class="checkEmptyInstallation(selectedProviders[data.index]) ? 'border-danger-for-v-select' : ''"
              />
              <small v-if="checkEmptyInstallation(selectedProviders[data.index])" class="text-danger">Please select
                provider</small>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BTable} from 'bootstrap-vue'
import OfferService from '@/services/OfferService'
import vSelect from 'vue-select'

export default {
  name: 'Installation',
  components: {
    BTable,
    vSelect,
  },
  props: ['offerId', 'selectedItems', 'installations', 'providers', 'offerInstallations'],
  data() {
    return {
      offerService: new OfferService(),
      localItems: [],
      providersList: [],
      selectedProviders: [],
      installationsMap: [],
      fields: [
        {
          key: 'name',
          label: 'Installation',
        },
        {
          key: 'provider',
          label: 'Provider',
          thStyle: {width: "20%"},
        },
      ],
    }
  },
  mounted() {
    this.mapIndex()
    this.getActiveProviders()
  },
  methods: {
    checkEmptyInstallation(item) {
      return item == null && this.$store.state.app.TableOfQuantityModalSubmitted
    },
    mapIndex() {
      this.installations.forEach((install, index) => {
        this.installationsMap[install.id] = index
      })
    },
    getActiveProviders() {
      this.offerInstallations.forEach(install => {
        this.providers.forEach(provider => {
          if (provider.id === install.provider_id) {
            this.selectedProviders[this.installationsMap[install.id]] = provider
            this.providersList.push({installation_id: install.id, provider_id: install.provider_id})
          }
        })
      })
    },
    saveProvider(event, record) {
      if (event != null) {
        // Find and change or add provider to array of providers
        if (this.providersList.find(item => item.installation_id === record.id)) {
          this.providersList.find(item => item.installation_id === record.id).provider_id = event.id
        } else {
          this.providersList.push({installation_id: record.id, provider_id: event.id})
        }
        this.offerService.updateTableOfQuantity(this.offerId, {installations: this.providersList}).then(res => {
        }).finally(() => {
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
