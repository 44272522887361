<template>
  <div class="card">
    <div class="card-body">
      <b-form-radio
          v-for="(option, index) in options"
          :key="index"
          v-model="value"
          name="some-radios"
          class="mb-1"
          :state="state"
          :value="option.id"
          :disabled="disabled"
          @change="radioChange"
      >
        {{ option.name }}
      </b-form-radio>
      <b-form-invalid-feedback class="mt-0" :state="state">
        Please choose an option
      </b-form-invalid-feedback>
      <b-form-group v-if="withNote">
        <b-form-input
            class="w-50"
            size="sm"
            :disabled="disabled"
            :debounce="1000"
            placeholder="Note"
            @update="textChanged"
            v-model="note"
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode";
import {BFormRadio, BFormInvalidFeedback} from "bootstrap-vue";

export default {
  name: "RadioButton",
  props: ['options', 'selected', 'label', 'disabled', 'withNote'],
  components: {
    BCardCode,
    BFormRadio,
    BFormInvalidFeedback
  },
  data() {
    return {
      value: null,
      note: undefined,
      isEmpty: true
    }
  },
  computed: {
    state() {
      if (!this.$store.state.app.TableOfQuantityModalSubmitted) {
        return null
      }
      if (!this.value) {
        if (this.disabled) {
          return null
        }
        return false
      } else {
        return null
      }
    },
  },
  methods: {
    radioChange(event) {
      this.isEmpty = false
      this.$emit('change', event)
    },
    textChanged(note) {
      this.$emit('noteChanged', note)
    }
  },
  mounted() {
    console.log(this.selected)
    if (this.selected) {
      this.value = this.selected.id
      this.note = this.selected.note
      if (this.value) {
        this.isEmpty = false
      }
    }
  }
}
</script>

<style scoped>

</style>
