<template>
  <b-modal
      :centered="true"
      id="TextAreaInputModal"
      ref="TextAreaInputModal"
      noCloseOnBackdrop
      :title="title"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
  >
    <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
          :state="textState"
          :label="`${inputLabel} (optional)`"
          label-for="name-input"
          :invalid-feedback="`${inputLabel} is required`"
      >
        <b-form-textarea
            id="name-input"
            v-model="text"
            :state="textState"
            autofocus

        />
      </b-form-group>
      <b-row class="justify-content-center">
        <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                  class="text-capitalize mb-2 mr-2"
                  :disabled="loading"
                  @click="submitModal">
          <b-spinner v-if="loading" small/>
          {{ submitBtnText }}
        </b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                  class="text-capitalize mb-2" @click="cancel">
          {{ cancelBtnText }}
        </b-button>
      </b-row>
    </form>
    <template #modal-footer>
      <div></div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BFormGroup, BFormTextarea, BModal, VBModal, BListGroup, BListGroupItem, BRow, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BModal,
    BRow,
    BSpinner
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    title: {
      default: "Reject offer form",
      type: String,
    },
    inputLabel: {
      default: "Reason",
      type: String,
    },
    submitBtnText: {
      default: "Submit",
      type: String,
    },
    cancelBtnText: {
      default: "Cancel",
      type: String,
    },

  },
  data: () => ({
    text: '',
    textState: null,
    loading: false,
    data: null
  }),
  methods: {
    open(data) {
      console.log(data)
      this.$bvModal.show('TextAreaInputModal');
      this.text = '';
      this.data = data
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.textState = valid
      return valid
    },
    cancel() {
      this.$bvModal.hide('TextAreaInputModal')
    },
    resetModal() {
      this.name = ''
      this.textState = null
      this.data = null
    },
    submitModal(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      console.log(this.data)
      this.$emit('result', {data: this.data, text: this.text})
    },
  },
}
</script>
