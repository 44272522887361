<template>
  <b-modal id="table-of-quantity-modal"
           size="xl"
           no-close-on-esc
           no-close-on-backdrop
           hide-footer
           scrollable
           @hidden="resetData"
           title="Table of Quantity"

  >
    <div class="mb-3">
      <div v-if="loading" class="loading-div d-flex align-items-center justify-content-center" style="height: 80vh">
        <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Large Spinner"
            :variant="'primary'"
        />
      </div>
      <div v-if="!loading &&  data" class="">

        <!--        table section-->
        <h3 class="text-center">Work Period Time : {{ data.offer.work_period }} days</h3>
        <b-button
            class="justify-content-center"
            style="position: absolute;right: 20px;top: 10px"
            variant="outline-primary"
            size="sm"
            :disabled="downloadExcel"
            @click="extractTable"
        >
          <span class="mr-1" v-if="downloadExcel">Loading...</span>
          <b-spinner v-if="downloadExcel" small/>
          <span v-if="!downloadExcel" class="mr-1">Extract</span>
          <feather-icon
              v-if="!downloadExcel"
              icon="DownloadIcon"
              size="16"
          />
        </b-button>

        <div v-for="(table, key, index) in tablesArray" :key="index" class="">
          <div v-if="table.length" class="">
            <TablesList
                ref="TablesList"
                :offerId="offerId"
                :typeName="key"
                :windowWidth="windowWidth"
                v-model="tablesArray[key]"
            />
          </div>
        </div>
        <h4 class="text-center border-2 mb-2 mt-2 rounded-top-left-sm rounded-top-left-lg">Number of frames:
          {{ framesCount }}</h4>
        <hr>
        <div class="">
          <h3 class="mb-2">Notes and Approvals</h3>
        </div>

        <!--        Product Category section-->
        <ProductCategory ref="ProductCategory" :categories="data.offer.grouped_categories"/>

        <!--        Items List section-->
        <ItemsList
            ref="ItemsList"
            :offerId="offerId"
            :items.sync="data.offer.grouped_items"
            :accessoriesLocation="data.list.accessories_location"
        />

        <!--        Aluminum Color section-->
        <AluminumColorSection
            ref="AluminumColorSection"
            :colors="data.list.colors"
            :selectedColor="data.offer.aluminum_colors"
            @change="updateAlumColor"
        />

        <!--        Accessories section-->
        <Accessories
            ref="Accessories"
            :offerId="offerId"
            :accessoriesModel="data.list.accessories_model"
            :selectedAccessoriesModel="data.offer.accessories_model"
        />

        <!--        Glass Details section-->
        <GlassDetails
            ref="GlassDetails"
            :offerId="offerId"
            :doorDetails="data.list.door_details"
            :frameDetails="data.list.frame_details"
            :colorsList="data.list.glass_colors"
            :glasses="data.offer.grouped_glasses"
        />

        <!--        Installation section-->
        <Installation
            ref="Installation"
            :offerId="offerId"
            :items="data.list.installations"
            :providers="data.list.providers"
            :installations="data.list.installations"
            :offerInstallations="data.offer.installations"
        />


        <!--        Attachments section-->
        <Attachments
            v-if="data"
            ref="Attachments"
            :offerId="offerId"
            :attachments="data.offer.attachments"
        />

        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="w-50 m-auto d-flex justify-content-center"
            variant="primary"
            :disabled="downloading"
            @click="downloadTemplate"
        >
          <span class="mr-1" v-if="downloading">Downloading...</span>
          <b-spinner v-if="downloading" small/>
          <span v-if="!downloading">Download</span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {BButton, BSpinner, BTable} from "bootstrap-vue";
import OfferService from "@/services/OfferService";
import TablesList from "@/views/components/seller/table of quantity/TablesList";
import AluminumColorSection
  from "@/views/components/seller/table of quantity/aluminum color section/AluminumColorSection";
import ProductCategory from "@/views/components/seller/table of quantity/ProductCategory";
import ItemsList from "@/views/components/seller/table of quantity/ItemsList";
import GlassDetails from "@/views/components/seller/table of quantity/GlassDetails";
import Installation from "@/views/components/seller/table of quantity/Installation";
import Accessories from "@/views/components/seller/table of quantity/Accessories";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Attachments from "@/views/components/seller/table of quantity/Attachments";

export default {
  name: "TableOfQuantityModal",
  components: {
    Attachments,
    Accessories,
    Installation,
    GlassDetails,
    ItemsList,
    AluminumColorSection,
    BTable,
    BSpinner,
    TablesList,
    ProductCategory,
    BButton
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      downloadExcel: false,
      downloading: false,
      tablesArray: {},
      offerService: new OfferService(),
      data: null,
      offerId: null, // filled from the component that uses this modal
      windowWidth: window.innerWidth,
      framesCount: 0,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.$store.commit('app/UPDATE_TABLE_OF_QUANTITY_SUBMIT_BTN', false)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    open() { // runs from the component that uses this modal
      this.$bvModal.show('table-of-quantity-modal');
      this.getData(this.offerId)
    },
    getData(offerId) {
      this.framesCount = 0
      this.loading = true
      this.offerService.getTableOfQuantity(offerId).then(res => {
        Object.keys(res.offer.items).forEach((key) => {
          res.offer.items[key].forEach((item, index) => {
            if (!this.tablesArray[key]) {
              this.tablesArray[key] = []
            }
            // item.index = index + 1
            item.savingSandBlast = false
            item.savingPlanLocation = false
            this.tablesArray[key].push(item)
            if (item.sub_items) {
              item.sub_items.forEach(subItem => {
                // item.index = index + 1
                subItem.savingSandBlast = false
                subItem.savingPlanLocation = false
                this.tablesArray[key].push(subItem)
              })
            }
            console.log('item is: ')
            console.log(item)
            this.framesCount += parseInt(item.quantity)
          })
        })
        this.data = res
      }).finally(() => {
        this.loading = false
      })
    },
    updateAlumColor(colors) {
      this.offerService.updateTableOfQuantity(this.offerId, {aluminum_colors: colors})
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    resetData() {
      this.data = null
      this.offerId = null
      this.tablesArray = {}
      this.$store.commit('app/UPDATE_TABLE_OF_QUANTITY_SUBMIT_BTN', false)
    },
    downloadTemplate() {
      this.downloading = false
      let isValidData = true
      this.$store.commit('app/UPDATE_TABLE_OF_QUANTITY_SUBMIT_BTN', true)
      // this.$refs.TablesList.forEach(table => {
      // })
      Object.keys(this.tablesArray).forEach((key) => {
        this.tablesArray[key].forEach((item, index) => {
          if (!item.plan_location) {
            isValidData = false
          }
        })
      })

      // access index 0 because there is animation component as parent to all select children
      this.$refs.AluminumColorSection.$children[0].$children.forEach(component => {
        if (component.$data && !component.$data.colorSection.colorId) {
          isValidData = false
        }
      })
      this.$refs.Accessories.$children.forEach(component => {
        if (component.$data.isEmpty) {
          isValidData = false
        }
      })
      if (this.$refs.GlassDetails.$refs.Checkbox.$data.isEmpty) {
        isValidData = false
      }
      if (this.$refs.GlassDetails.$data.isChecked) {
        console.log('this.$refs.GlassDetails.$refs', this.$refs.GlassDetails.$refs)
        if (this.$refs.GlassDetails.$refs.CheckboxWithNotes.$data.isEmpty) {
          isValidData = false
        }
        if (this.$refs.GlassDetails.$refs.RadioButton.$data.isEmpty) {
          isValidData = false
        }
      }


      if (this.$refs.Installation.$data.providersList.length !== this.data.list.installations.length) {
        isValidData = false
      }

      // if (this.$refs.Attachments.$data.data.files.length < 4) {
      //   isValidData = false
      // }

      if (isValidData) {
        this.downloading = true
        this.offerService.generateTableOfQuantityZIP(this.offerId, this.data.offer.file_name)
            .then(res => {
              console.log(res)
            }).finally(() => {
          this.downloading = false
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Required Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            // eslint-disable-next-line camelcase
            text: 'Please Fill all the required fields',
          },
        })
      }
    },
    extractTable() {
      let isValidData = true
      Object.keys(this.tablesArray).forEach((key) => {
        this.tablesArray[key].forEach((item, index) => {
          if (!item.plan_location) {
            isValidData = false
          }
        })
      })
      if (!isValidData) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Required Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            // eslint-disable-next-line camelcase
            text: 'Please Fill all the Plan location to extract the table',
          },
        })
      } else {
        this.downloadExcel = true
        this.offerService.extractTable(this.offerId, this.data.offer.file_name)
            .then(res => {
              console.log(res)
            }).finally(() => {
          this.downloadExcel = false
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
