<template>
  <div class="card">
    <div class="card-body">
      <div class="">
        <h4>Glass Details</h4>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-12">
              <Checkbox
                  ref="Checkbox"
                  class="mb-0"
                  @checkboxChanged="updateGlassDetails($event,'glass_color_ids')"
                  @textChanged="updateGlassDetails($event,'glass_color')"
                  label="Glass Color"
                  :options="colorsList"
                  :selectedItems="glasses.glass_colors"
                  :withOthers="true"
              />
            </div>
            <div class="col-12">
              <div class="card mb-0">
                <div class="card-body">
                  <b-form-group class="mb-0" label="Glass Type">
                    <p
                        v-for="(glass, index) in glasses.glass_types"
                        :key="index"
                        style="font-size: 12px"
                    >
                      {{ glass !== '-' ? glass : '' }}
                    </p>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <h5>Design</h5>
          <RadioButton
              ref="RadioButton"
              :options="frameDetails"
              :withNote="true"
              @change="updateGlassDetails($event,'frame_detail_id')"
              @noteChanged="updateGlassDetails($event,'frame_detail_note')"
              :selected="glasses.frame_detail"
              class="mb-0"

          />
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-12">
              <div class="card mb-0">
                <div class="card-body">
                  <b-form-group label="Architrave">
                    <b-form-checkbox
                        v-model="isChecked"
                        class="custom-control-primary"
                        name="check-button"
                        @change="updateGlassDetails($event,'architrave', isChecked)"
                        :disabled="savingArchitrave"
                        switch
                    />
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="col-12">
              <CheckboxWithNotes
                  ref="CheckboxWithNotes"
                  label="Other details"
                  :options="doorDetails"
                  :disabled="!isChecked"
                  @Changed="updateGlassDetails($event,'door_details')"
                  :selectedItems="glasses.door_details"
                  class="mb-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/views/components/seller/table of quantity/Checkbox";
import {BFormGroup, BFormCheckbox} from "bootstrap-vue";
import OfferService from "@/services/OfferService";
import RadioButton from "@/views/components/seller/table of quantity/RadioButton";
import CheckboxWithNotes from "@/views/components/seller/table of quantity/CheckboxWithNotes";

export default {
  name: "GlassDetails",
  components: {RadioButton, Checkbox, BFormGroup, BFormCheckbox, CheckboxWithNotes},
  props: ['glasses', 'colorsList', 'architrave', 'doorDetails', 'frameDetails', 'offerId'],
  data() {
    return {
      savingArchitrave: false,
      offerService: new OfferService(),
      isChecked: true
    }
  },
  methods: {
    updateGlassDetails(value, key, architrave = undefined) {
      this.offerService.updateTableOfQuantity(this.offerId, {[key]: value}).then(res => {
      }).finally(() => {
      })
      if (architrave !== undefined) {
        if (!architrave) {
          this.glasses.door_details = []
          console.log('this.$refs.CheckboxWithNotes.$data.localOptions', this.$refs.CheckboxWithNotes.$data.localOptions)
          this.$refs.CheckboxWithNotes.$data.localOptions.forEach(option => {
            option.checked = false
            option.note = null
          })
          this.$refs.RadioButton.$data.value = null
          this.$refs.RadioButton.$data.note = null
        }
      }
    },
    test(e) {
      console.log(e)
    }
  },
  mounted() {
    console.log(this.glasses)
    this.isChecked = this.glasses.architrave === 'Yes'
  }
}
</script>

<style scoped>

</style>
