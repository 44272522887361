<template>
  <div class="">
    <offer-list :propsParams="{withTrash: 1}" :showAll="false" />
  </div>
</template>

<script>
import OfferList from "@/views/components/seller/OfferList";
import GoodTableSsr from "@/views/table/vue-good-table/GoodTableSsr";
import '@core/scss/vue/libs/vue-good-table.scss';
export default {
  name: "Index",
  components: {
    OfferList,
    GoodTableSsr
  }
}
</script>

<style scoped lang="scss">

</style>
