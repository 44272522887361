<template>
  <div class="pdf-container">
    <VuePdfEmbed
        :source="source"
        @loaded="$emit('loaded')"
        @loading-failed="$emit('loading-failed')"
    />
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: "PDF-viewer",
  props: ['source'],
  components: {
    VuePdfEmbed,
  },
}
</script>

<style scoped>
.pdf-container {
  max-height: 80vh;
  overflow: auto
}
</style>
