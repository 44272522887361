<template>
  <div class="">
    <div class="row">
      <div class="col-md-2">
        <b-form-group
            label="Color Type"
        >
          <v-select
              v-model="colorSection.typeName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              @input="colorTypeChanged"
              label="title"
              :class="!isValidType ? 'border-danger-for-v-select' : ''"
              placeholder="Select color type"
              :options="colorSection.colorsTypeList"
          />
          <small v-if="!isValidType" class="text-danger">Please select color type</small>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group
            label="Color Code"
        >
          <v-select
              v-model="colorSection.selectedColor"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="colorSection.colorsCodeList[colorSection.typeName]"
              @input="colorCodeChanged"
              :class="!isValidCode ? 'border-danger-for-v-select' : ''"
              placeholder="Search and select color code"
              :clearable="false"
              label="code"
          />
          <small v-if="!isValidCode" class="text-danger">Please select color code</small>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group
            label="Color Name"
        >
          <div v-if="colorSection.selectedColor" class="">
            {{ colorSection.selectedColor.name }}
          </div>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group
            label="Color Sample"
        >
          <div v-if="colorSection.selectedColor && colorSection.selectedColor.media.length >0" class="">
            <BImgLazy class="color-sample"
                      :src="colorSection.selectedColor.media[0].original_url"
                      alt="color sample image"/>
          </div>
        </b-form-group>
      </div>
      <div class="col-md-3">
        <b-form-group
            label="Note"
        >
          <b-form-input
              v-model="colorSection.note"
              debounce="1000"
              @update="colorNoteChanged"
          />
        </b-form-group>
      </div>
      <div class="col-md-1 d-flex align-items-center justify-content-end" v-if="withPlusIcon">
        <b-button
            v-if="index+1 === colorsLength"
            class="cursor-pointer"
            :class="colorsLength !== 1 ? 'mr-1':''"
            variant="primary"
            size="sm"
            @click="addNewColor"
        >
          <feather-icon icon="PlusIcon"/>
        </b-button>
        <b-button
            v-if="colorsLength !== 1"
            variant="danger"
            class=" cursor-pointer"
            size="sm"
            @click="removeColor(index)"
        >
          <feather-icon icon="MinusIcon"/>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {BFormGroup, BImgLazy} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "ColorSelect",
  props: ['colors', 'selectedColor', 'withPlusIcon', 'index', 'colorsLength'],
  components: {
    BFormGroup,
    BImgLazy,
    vSelect
  },
  data() {
    return {
      colorSection: {
        selectedColor: {media: []},
        typeName: null,
        colorId: null,
        note: undefined,
        colorsTypeList: [],
        colorsCodeList: {},
      },
    }
  },
  computed: {
    isValidType() {
      if (!this.$store.state.app.TableOfQuantityModalSubmitted) {
        return true
      }
      return this.colorSection.typeName;
    },
    isValidCode() {
      if (!this.$store.state.app.TableOfQuantityModalSubmitted) {
        return true
      }
      return this.colorSection.colorId;
    },
  },
  mounted() {
    this.colorSection.colorsCodeList = this.colors
    if (this.selectedColor) {
      this.colorSection.selectedColor = this.selectedColor
      this.colorSection.typeName = this.selectedColor.type
      this.colorSection.colorId = this.selectedColor.id
      this.colorSection.note = this.selectedColor.note
    }
    Object.keys(this.colors).forEach((key) => {
      this.colorSection.colorsTypeList.push(key)
    })
  },
  methods: {
    colorTypeChanged(typeName) {
      this.colorSection.selectedColor = null
      this.colorSection.colorId = null
    },
    colorCodeChanged(color) {
      this.colorSection.colorId = color.id
      this.$emit('selected', color.id)
    },
    colorNoteChanged(colorNote) {
      this.colorSection.note = colorNote
      this.$emit('noteChange', colorNote)
    },
    addNewColor() {
      this.$emit('addNewColor')
    },
    removeColor(index) {
      this.$emit('removeColor', index)
    },
  }
}
</script>

<style scoped>
.color-sample {
  height: 38px;
  border-radius: 6px;
  width: 100%;
  object-fit: cover;
}
</style>
